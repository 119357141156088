import { useState, useEffect, useRef, Fragment } from "react";
import { Route, Link, useLocation } from "wouter";
import { a, useSpring } from "@react-spring/web";
import useWindowDimensions from "../useWindowDimensions";
import Loading from "./Loading";
import useScrollTo from "react-spring-scroll-to-hook";

import useStore from "../zustand";
import { useSwipeable } from "react-swipeable";

import Image from "./Image";
import WebGL from "./WebGL";
import Iphonevideo from "./Iphonevideo";
import PDF from "./PDF";
import Publication from "./Publication";
import Text from "./Text";
import Video from "./Video";
import produce from "immer";
import KeyboardEventHandler from "react-keyboard-event-handler";
import { sortBy } from "lodash";
export default function Content() {
  const { scrollTo } = useScrollTo();
  // const currentHover = useAtom(currentHoverAtom);
  const { width } = useWindowDimensions();
  const [location, setLocation] = useLocation();
  const [isInProject, setIsInProject] = useState();
  // check if there is something in the url

  useEffect(() => {
    setIsInProject(location.indexOf("/p/") === -1 ? false : true);
  }, [location]);

  const [spring, api] = useSpring(() => ({
    x: -width - 200,
    opacity: 0.4,
  }));

  useEffect(() => {
    api.start({
      x: isInProject ? 0 : -width - 200,
      opacity: isInProject ? 1 : 0.4,
    });
  }, [isInProject, width]);

  const [bear, setBear] = useState();
  const [page, setPage] = useState();
  const [nextBear, setNextBear] = useState();
  const [prevBear, setPrevBear] = useState();

  const data = useStore((s) => s.data);
  const [mergedData, setMergedData] = useState([]);

  useEffect(() => {
    setMergedData([]);
    data.map((items) => {
      // items.map((item, i) =>
      setMergedData((mergedData) => [...mergedData, items]);
      // );
    });
  }, [data]);

  useEffect(() => {
    mergedData.map((item, i) => {
      if ("/p/" + item.id === location) {
        setPage(i);
        setBear(mergedData[i]);
        if (mergedData[i + 1]) setNextBear(mergedData[i + 1].id);
        if (mergedData[i - 1]) setPrevBear(mergedData[i - 1].id);
      }
    });
  }, [mergedData, location]);

  // is this svg and publications?
  const [pageNumber, setPageNumber] = useState(0);
  const [numPages, setNumPages] = useState();
  useEffect(() => {
    if (bear && bear.folder) setNumPages(bear.folder.length);
  }, [bear, numPages]);

  const swiper = useRef();

  const [startLoading, setStartLoading] = useState(false);

  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const minSwipeDistance = 50;

  const onTouchStart = (e) => {
    if (startLoading) return;
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
    // console.log("touch start");
    swiper.current.style.display = "none";
  };

  const onTouchMove = (e) => {
    if (startLoading) return;
    // if (!touchStart || !touchEnd) return;
    const distance = touchStart - e.targetTouches[0].clientX;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      swiper.current.style.display = "flex";
      swiper.current.innerHTML = "→";
    } else if (isRightSwipe) {
      swiper.current.style.display = "flex";
      swiper.current.innerHTML = "←";
    }
    setTouchEnd(e.targetTouches[0].clientX);
  };
  const onTouchEnd = () => {
    if (startLoading) return;
    swiper.current.style.display = "none";
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isLeftSwipe) {
      if (mergedData.length > page + 1) {
        setLoading(true);
        setLocation(nextBear);
        setPageNumber(0);
      }
    } else if (isRightSwipe) {
      if (page > 0) {
        setLoading(true);
        setLocation(prevBear);
        setPageNumber(0);
      }
    }
  };

  // useEffect(() => {
  //   document.addEventListener("keydown", function (event) {
  //     // console.log(
  //     //   `Key: ${event.key} with keycode ${event.keyCode} has been pressed`
  //     // );
  //     console.log(event);

  //   });
  // }, []);
  useEffect(() => {
    document.addEventListener("keydown", (e) => {
      if (e.key === "ArrowRight" && mergedData.length > page + 1) {
        setLocation(nextBear);
        setLoading(true);
        setPageNumber(0);
      }
      if (e.key === "ArrowLeft" && page > 0) {
        setLocation(prevBear);
        setLoading(true);
        setPageNumber(0);
      }
      if (e.key === "Escape") {
        setLocation("/");
        setLoading(true);
        setPageNumber(0);
      }
    });
  }, [mergedData.length, nextBear, prevBear, page]);
  const [loading, setLoading] = useState(false);
  const onLoadHandler = () => {
    setLoading(false);
  };
  return (
    <>
      {isInProject && (
        <Fragment>
          <Link
            to="/"
            onClick={() => {
              setIsInProject(false);
              console.log("click");
              setPageNumber(0);
            }}
          >
            <div
              style={{
                position: "fixed",
                top: 0,
                width: "100%",
                height: "100%",
                cursor: "pointer",
              }}
            />
          </Link>
        </Fragment>
      )}
      <a.div
        style={{
          position: "fixed",
          top: 0,
          width: width > 700 ? "calc(100% - 48px)" : "100%",
          height: width > 700 ? "calc(100% - 48px)" : "100%",
          margin: width > 700 ? 24 : 0,
          overflow: "scroll",
          left: 0,
          backgroundColor: "white",
          zIndex: 5,
          // fontSize: width > 500 ? 16 : 11,
          // textAlign: width < 700 ? "center" : "left",
          ...spring,
        }}
        className="supershadow"
      >
        <>
          {/* <KeyboardEventHandler
            handleKeys={["ArrowRight", "ArrowLeft", "Escape"]}
            onKeyEvent={(key, e) => {
              console.log(`do something upon keydown event of ${key}`);

              if (key === "ArrowRight" && mergedData.length > page + 1) {
                setLocation(nextBear);
                setLoading(true);
                setPageNumber(0);
              }
              if (key === "ArrowLeft" && page > 0) {
                setLocation(prevBear);
                setLoading(true);
                setPageNumber(0);
              }
              if (key === "Escape") {
                setLocation("/");
                setLoading(true);
                setPageNumber(0);
              }
            }}
          /> */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor: "#e2e8f0",
            }}
          >
            <div
              style={{
                display: width > 500 ? "flex" : "block",
                // display: "flex",
                userSelect: "none",
              }}
            >
              <div style={{ margin: 6 }} onClick={() => setPageNumber(0)}>
                <Link href={`/`}>Close</Link>
              </div>
              {width > 700 && (
                <Fragment>
                  <div
                    style={{ margin: 6, display: "inline-block" }}
                    onClick={() => {
                      if (page > 0) {
                        setPageNumber(0);
                        setLoading(true);
                      }
                    }}
                  >
                    {page > 0 ? (
                      <Link href={`/p/${prevBear}`}>← Previous</Link>
                    ) : (
                      <div style={{ color: "grey" }}>← Previous</div>
                    )}
                  </div>
                  <div
                    style={{ margin: 6, display: "inline-block" }}
                    onClick={() => {
                      if (mergedData.length > page + 1) {
                        setPageNumber(0);
                        setLoading(true);
                      }
                    }}
                  >
                    {mergedData.length > page + 1 ? (
                      <Link href={`/p/${nextBear}`}>Next →</Link>
                    ) : (
                      <div style={{ color: "grey" }}>Next →</div>
                    )}
                  </div>
                </Fragment>
              )}
              {bear && (bear.type === "svg" || bear.type === "publication") && (
                <>
                  <div
                    style={{
                      margin: 6,
                      color: pageNumber > 0 ? "#3b82f6" : "grey",
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                    onClick={() => {
                      if (pageNumber > 0) setPageNumber(pageNumber - 1);
                    }}
                  >
                    ← {width > 800 && <>Previous Page</>}
                  </div>
                  <div
                    style={{
                      margin: 6,
                      display: "inline-block",
                    }}
                  >
                    {pageNumber + 1}
                  </div>
                  <div
                    style={{
                      margin: 6,
                      color: pageNumber < numPages - 1 ? "#3b82f6" : "grey",
                      cursor: "pointer",
                      display: "inline-block",
                    }}
                    onClick={() => {
                      if (pageNumber < numPages - 1)
                        setPageNumber(pageNumber + 1);
                    }}
                  >
                    {width > 800 && <>Next Page</>} →
                  </div>
                </>
              )}
            </div>
            <div>
              {bear && bear.project !== "NOT A PROJECT" && (
                <div
                  style={{
                    margin: 6,
                    float: "right",
                    display: "inline-block",
                    color: "#3b82f6",
                  }}
                  onClick={() => {
                    setPageNumber(0);
                    scrollTo();
                    useStore.setState({
                      selectedtitles: [bear.project],
                    });
                  }}
                >
                  <Link href={`/`}>{bear && bear.project}</Link>
                </div>
              )}
              {bear && bear.type === "publication" && bear.publikationurl && (
                <div
                  style={{
                    margin: 6,
                    display: "inline-block",
                    float: "right",
                    color: "#3b82f6",
                  }}
                >
                  <a href={bear.publikationurl} target="_blank">
                    {bear.publikationtitel && bear.publikationtitel}
                  </a>
                </div>
              )}
            </div>
          </div>
          <Route path="/p/:id">
            <Loading loading={loading} />
            {bear &&
              (bear.type === "image" ||
                bear.type === "svg" ||
                bear.type === "publication" ||
                bear.type === "video" ||
                bear.type === "iphonevideo" ||
                bear.type === "webgl" ||
                bear.type === "text") && (
                <div
                  // {...bind()}
                  onTouchStart={onTouchStart}
                  onTouchMove={onTouchMove}
                  onTouchEnd={onTouchEnd}
                  style={{
                    height:
                      width < 700 ? "calc(100% - 62px)" : "calc(100% - 32px)",
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    overflow: bear && bear.type === "video" && "hidden",
                    // touchAction: "none",
                    // margin: 12,
                  }}
                  // {...((bear.type === "image" ||
                  //   bear.type === "svg" ||
                  //   bear.type === "publication" ||
                  //   bear.type === "video" ||
                  //   bear.type === "text") &&
                  //   handlers)}
                >
                  {bear && bear.type === "image" && (
                    <Image
                      bear={bear}
                      onLoadHandler={onLoadHandler}
                      // handlers={handlers}
                    />
                  )}
                  {bear && bear.type === "svg" && (
                    <PDF
                      item={bear}
                      pageNumber={pageNumber}
                      setLoading={setLoading}
                    />
                  )}
                  {bear && bear.type === "publication" && (
                    <Publication
                      item={bear}
                      pageNumber={pageNumber}
                      setLoading={setLoading}
                    />
                  )}
                  {bear && bear.type === "video" && (
                    <Video item={bear} setLoading={setLoading} />
                  )}
                  {bear && bear.type === "iphonevideo" && (
                    <Iphonevideo item={bear} setLoading={setLoading} />
                  )}
                  {bear && bear.type === "text" && (
                    <Text item={bear} setLoading={setLoading} />
                  )}
                  {bear && bear.type === "webgl" && (
                    <WebGL
                      item={bear}
                      setLoading={setLoading}
                      startLoading={startLoading}
                      setStartLoading={setStartLoading}
                    />
                  )}
                </div>
              )}
            <div
              ref={swiper}
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                width: "100%",
                height: "100%",
                display: "none",
                alignItems: "center",
                justifyContent: "center",
                color: "white",
                animation: "loading 4s ease-in-out infinite",
                animationDirection: "alternate",
                fontSize: "8vw",
                userSelect: "none",
                pointerEvents: "none",
              }}
            ></div>

            {/* desktop arrows */}
            {width > 700 && (
              <Fragment>
                {/* {bear && bear.type !== "text" && ( */}

                {page > 0 && (
                  <div
                    onClick={() => {
                      setPageNumber(0);
                      setLoading(true);
                    }}
                    style={{
                      position: "absolute",
                      width: 100,
                      height: "calc(100% - 142px)",
                      left: 0,
                      top: 62,
                      cursor: "pointer",
                    }}
                  >
                    <Link href={`/p/${prevBear}`}>
                      <div style={{ width: "100%", height: "100%" }} />
                    </Link>
                  </div>
                )}
                {/* )} */}
                {mergedData.length > page + 1 && (
                  <div
                    onClick={() => {
                      setPageNumber(0);
                      setLoading(true);
                    }}
                    style={{
                      position: "absolute",
                      width: 100,
                      height: "calc(100% - 142px)",
                      right: 0,
                      top: 62,
                      cursor: "pointer",
                    }}
                  >
                    <Link href={`/p/${nextBear}`}>
                      <div style={{ width: "100%", height: "100%" }} />
                    </Link>
                  </div>
                )}
              </Fragment>
            )}
            {width < 700 && (
              <div
                style={{
                  height: 32,
                  backgroundColor: "rgb(226, 232, 240)",
                  display: "flex",
                  position: "fixed",
                  bottom: 0,
                  width: "100%",
                }}
              >
                {page > 0 && bear && (
                  <div
                    onClick={() => {
                      setPageNumber(0);
                      setLoading(true);
                    }}
                    style={{
                      lineHeight: 1.8,
                      textAlign: "center",
                      height: 32,
                      width: "50%",
                      color: "#3b82f6",
                    }}
                  >
                    <Link href={`/p/${prevBear}`}>←</Link>
                  </div>
                )}
                {mergedData.length > page + 1 && width < 700 && (
                  <div
                    onClick={() => {
                      setPageNumber(0);
                      setLoading(true);
                    }}
                    style={{
                      lineHeight: 1.8,
                      height: 32,
                      width: "50%",
                      textAlign: "center",
                      color: "#3b82f6",
                    }}
                  >
                    <Link href={`/p/${nextBear}`}>→</Link>
                  </div>
                )}
              </div>
            )}
          </Route>
        </>
      </a.div>
    </>
  );
}
