import useStore from "../zustand";
export default function Contact() {
  return (
    <div style={{ display: "flex" }}>
      <div
        style={{
          color: "#1E293B",
          cursor: "pointer",
          width: "50%",
          padding: 4,
          textAlign: "center",
          backgroundColor: "#FDE68A",
        }}
        onClick={() => {
          // setOpen(!open);
          useStore.setState({ opencontact: true });
        }}
      >
        Contact <div className="at">@</div>
      </div>
      <div
        style={{
          padding: 4,
          textAlign: "center",
          width: "50%",
          color: "#1E293B",
          cursor: "pointer",
          backgroundColor: "#fd8a8a",
        }}
      >
        <a href="https://unprofessional.studio/" style={{ color: "black" }}>
          Studio
        </a>
      </div>
    </div>
  );
}
