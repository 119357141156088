import useScrollTo from "react-spring-scroll-to-hook";

import useStore from "../zustand";
import produce from "immer";
export default function TagTags({ currentHover, currentprojecttag, api }) {
  const { scrollTo } = useScrollTo();
  return (
    <>
      {currentHover[0] &&
        currentHover[0].tags.split(", ").map(
          (tag, i) =>
            tag && (
              <div
                key={i}
                style={{
                  display: "inline-block",
                  color: currentprojecttag.includes(tag)
                    ? "#3b82f6"
                    : "#475569",
                  border: currentprojecttag.includes(tag)
                    ? "1px solid #3b82f6"
                    : "1px solid #475569",
                  "&:hover": {
                    color: currentprojecttag.includes(tag)
                      ? "#3b82f6"
                      : "#2f3845",
                  },
                  margin: 2,
                  padding: "4px 6px",
                  lineHeight: "12px",
                  borderRadius: 14,
                  cursor: "pointer",
                }}
                onClick={() => {
                  const index = currentprojecttag.findIndex(
                    (prod) => prod === tag
                  );
                  useStore.setState(
                    produce((state) => {
                      if (state.selectedtags.includes(tag)) {
                        state.selectedtags.splice(index, 1);
                      } else {
                        state.selectedtags.push(tag);
                      }
                    })
                  );
                  scrollTo();
                  api.start({
                    y: 16,
                  });
                }}
              >
                #{tag}
              </div>
            )
        )}
    </>
  );
}
