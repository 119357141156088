import { useEffect, useCallback } from "react";
import { a, useSpring } from "@react-spring/web";
import useMeasure from "react-use-measure";
import { useAtom } from "jotai";
import Marquee from "react-fast-marquee";
import { Link, useLocation } from "wouter";
import useScrollTo from "react-spring-scroll-to-hook";
import useWindowDimensions from "../useWindowDimensions";
import Projects from "./Projects";
import {
  currentHoverAtom,
  columnsAtom,
  buttonAtom,
  shufflerAtom,
} from "../Masonry/Masonry";

import TitleTag from "./TitleTag";

import TagTags from "./TagTags";
import useMedia from "./useMedia";
import Contact from "./Contact";
import Tags from "./Tags";
import Slider from "./Slider";
import MyButton from "./MyButton";

import useStore from "../zustand";
import Architect from "./Architect";
import City from "./City";
import Typology from "./Typology";
import CityTags from "./CityTags";
import ArchitectTags from "./ArchitectTags";
import TypologyTags from "./TypologyTags";

let small = false;
export default function Button() {
  const { scrollTo } = useScrollTo();
  const { width } = useWindowDimensions();
  // check if in project view
  const [location, setLocation] = useLocation();
  // console.log(location);
  useEffect(() => {
    if (location !== "/") {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "inherit";
    }
  }, [location]);

  const [columns, setColumns] = useAtom(columnsAtom);
  const [open, setOpen] = useAtom(buttonAtom);
  const [, setShuffler] = useAtom(shufflerAtom);

  const [spring, api] = useSpring(() => ({
    x: 0,
    y: 0,
    height: 36,
    width: 36,
  }));

  useEffect(() => {
    if (width < 700) {
      if (small === false) {
        setColumns(3);
        // console.log("small");
        small = true;
      }
    } else {
      small = false;
    }
  }, [width]);

  const mediaColumns = useMedia(
    ["(min-width: 1500px)", "(min-width: 1000px)", "(min-width: 600px)"],
    [5, 4, 3],
    2
  );
  useEffect(() => {
    setColumns(mediaColumns);
  }, []);

  const increaseColumns = useCallback(() => {
    scrollTo();
    if (width > 700) {
      // console.log("click");
      if (columns < 12) {
        setColumns(columns + 1);
      }
    } else {
      api.start({
        y: 16,
        x: 16,
      });
      if (columns < 3) {
        setColumns(columns + 1);
      }
    }
  }, [columns, api]);
  const decreaseColumns = useCallback(() => {
    scrollTo();
    api.start({
      y: 16,
      x: 16,
    });
    columns > 1 && setColumns(columns - 1);
  }, [columns, api, width]);

  const currentHover = useAtom(currentHoverAtom);
  const currenttitletag = useStore((s) => s.selectedtitles);
  const currentprojecttag = useStore((s) => s.selectedtags);
  const currentcitytag = useStore((s) => s.selectedcity);
  const currentarchitecttag = useStore((s) => s.selectedarchitects);
  const currenttypologytag = useStore((s) => s.selectedtypology);

  // const contact = useStore((s) => s.contact);

  const [ref, { height }] = useMeasure();

  const nocontent = useStore((s) => s.nocontent);

  useEffect(() => {
    if (currentHover[0]) {
      api.start({
        x:
          //if open and desktop
          open
            ? Math.min(
                Math.max(
                  parseInt(currentHover[0].x + currentHover[0].width / 2 - 120),
                  12
                ),
                width - 260
              )
            : //if closed
              currentHover[0].x + currentHover[0].width / 2 - 18,
        y:
          open && !nocontent
            ? currentHover[0].y + currentHover[0].height / 2 - 68
            : nocontent
            ? 16
            : currentHover[0].y + currentHover[0].height / 2 - 18,

        width: open ? 240 : 36,
        height: open ? height + 36 : 36,
      });
    }
  }, [currentHover, open, nocontent]);

  const resettags = useCallback(() => {
    if (
      currenttitletag.length === 0 &&
      currentprojecttag.length === 0 &&
      currentcitytag.length === 0 &&
      currentarchitecttag.length === 0 &&
      currenttypologytag.length === 0
    )
      return;
    useStore.setState({
      selectedtitles: [],
      selectedtags: [],
      selectedarchitects: [],
      selectedtypology: [],
      selectedcity: [],
    });
    scrollTo();

    api.start({
      y: 16,
      x: 16,
    });
  }, [
    currenttitletag,
    currentprojecttag,
    currentcitytag,
    currentarchitecttag,
    currenttypologytag,
    api,
  ]);
  return (
    <>
      <a.div
        style={{
          position: "absolute",
          display: "inline-block",
          top: 0,
          height: 36,
          width: 36,
          borderRadius: 36 / 2,
          overflow: "hidden",
          backgroundColor: "white",
          ...spring,
        }}
        className="supershadow"
      >
        <div
          onClick={() => setOpen(!open)}
          style={{
            width: "100%",
            height: 36,
            cursor: "pointer",
            textAlign: "center",
            color: "white",
            backgroundColor: "#10b981",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {open && (
            <Marquee
              gradient={false}
              style={{
                padding: 6,
              }}
            >
              © unprofessional studio{" "}
            </Marquee>
          )}
        </div>

        <div ref={ref} style={{ position: "absolute", width: 240 }}>
          <div>
            <Link href={`/p/${currentHover[0] && currentHover[0].id}`}>
              <MyButton />
            </Link>
            <div style={{ margin: "6px 8px 6px 8px" }}>
              {currentHover[0] &&
                currentHover[0].project !== "NOT A PROJECT" && (
                  <TitleTag
                    currentHover={currentHover}
                    currenttitletag={currenttitletag}
                    currentprojecttag={currentprojecttag}
                    api={api}
                  />
                )}

              {/* ///// */}
              <TagTags
                currentHover={currentHover}
                currentprojecttag={currentprojecttag}
                api={api}
              />
              <CityTags
                currentHover={currentHover}
                currentcitytag={currentcitytag}
                api={api}
              />
              <ArchitectTags
                currentHover={currentHover}
                currentarchitecttag={currentarchitecttag}
                api={api}
              />
              <TypologyTags
                currentHover={currentHover}
                currenttypologytag={currenttypologytag}
                api={api}
              />
            </div>
          </div>
          <div
            style={{
              whiteSpace: "nowrap",
              margin: "0",
            }}
          >
            <div
              style={{
                padding: "4px 0",
                backgroundColor: "#dbeafe",
              }}
            >
              <Marquee gradient={false} style={{}}>
                {currentHover[0] && currentHover[0].title}
              </Marquee>
            </div>
          </div>
          <div style={{ border: "0px solid #dbeafe", margin: 4 }}>
            <Slider />
          </div>
          <div style={{ margin: "0px 8px 8px 8px" }}>
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  alignItems: "baseline",
                  width: 132,
                  margin: 4,
                }}
              >
                <div>Clm: {columns} </div>
                <div
                  style={{
                    width: width > 700 ? 18 : 24,
                    height: width > 700 ? 18 : 24,
                    lineHeight: width > 700 ? "14px" : "20px",
                    border: "1px solid black",
                    textAlign: "center",
                    margin: "0px 4px",
                    marginLeft: 8,
                    userSelect: "none",
                    WebkitUserSelect: "none",
                    cursor: "pointer",
                  }}
                  onClick={increaseColumns}
                >
                  +
                </div>
                <div
                  style={{
                    width: width > 700 ? 18 : 24,
                    height: width > 700 ? 18 : 24,
                    lineHeight: width > 700 ? "14px" : "20px",
                    border: "1px solid black",
                    textAlign: "center",
                    margin: "0px 4px",
                    userSelect: "none",
                    WebkitUserSelect: "none",
                    cursor: "pointer",
                  }}
                  onClick={decreaseColumns}
                >
                  -
                </div>
              </div>
            </div>
            <div style={{}} className="button" onClick={resettags}>
              Reset Tags
            </div>

            <div
              style={{}}
              className="button"
              onClick={() => setShuffler(true)}
            >
              Shuffle
            </div>
          </div>
          <Projects
            length={currenttitletag.length}
            width={width}
            api={api}
            open={open}
          />
          <City
            length={currentcitytag.length}
            width={width}
            api={api}
            open={open}
          />
          <Architect
            length={currentarchitecttag.length}
            width={width}
            api={api}
            open={open}
          />
          <Typology
            length={currenttypologytag.length}
            width={width}
            api={api}
            open={open}
          />
          <Tags
            length={currentprojecttag.length}
            width={width}
            api={api}
            open={open}
          />
          <Contact />
        </div>
      </a.div>
    </>
  );
}
