import Model from "./Model";
import { useState, Suspense, useEffect, useRef, Fragment } from "react";
import { Canvas, useLoader, useFrame } from "@react-three/fiber";
import ReactPlayer from "react-player/lazy";
import {
  Stage,
  OrbitControls,
  Html,
  useProgress,
  useDetectGPU,
} from "@react-three/drei";

function Loader() {
  // const { progress } = useProgress();
  return (
    <Html center style={{ width: 300, textAlign: "center" }}>
      <div className="buttonflicker">
        {/* <div>{progress}% loading</div> */}
        <div>Loading…</div>
        <div>Might take a while</div>
      </div>
    </Html>
  );
}
export default function Webgl({
  item,
  setLoading,
  startLoading,
  setStartLoading,
}) {
  useEffect(() => {
    setStartLoading(false);
  }, [item]);
  useEffect(() => {
    setLoading(false);
  }, [item]);
  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
        touchAction: "pan-y",
      }}
      className="grabber"
    >
      {item.previewtype === "video" && !startLoading && (
        <ReactPlayer
          playing
          loop={true}
          muted={true}
          url={item.small}
          // className="player"
          width="100%"
          height="100%"
          style={{
            filter: "blur(10px)",
            objectFit: "cover",
            position: "absolute",
          }}
        />
      )}
      {item.previewtype === "image" && !startLoading && (
        <img
          src={item.small}
          width="100%"
          height="100%"
          style={{
            filter: "blur(10px)",
            objectFit: "cover",
            position: "absolute",
          }}
        />
      )}
      {/* {startLoading && (
        <div style={{ position: "absolute", top: 0, left: 0, margin: 12 }}>
           {isMobile ? (
            <Fragment>
              <div>Touch and drag: Move camera around object</div>
              <div>Pinch: Distance and pan</div>
            </Fragment>
          ) : (
            <Fragment>
              <div>Click and drag: Move camera around object</div>
              <div>Wheel: Distance</div>
              <div>Hold Shift and click: Pan Camera</div>
            </Fragment>
          )} 
        </div>
      )} */}
      <Canvas>
        {!startLoading ? (
          <Html center style={{ width: 500, textAlign: "center" }}>
            <Fragment>
              <div
                onClick={() => setStartLoading(true)}
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  backgroundColor: "rgb(71, 85, 105)",
                  padding: 4,
                  borderRadius: 8,
                }}
                class="buttonwhite"
              >
                Start Loading 3D Model: {item.size}
              </div>
            </Fragment>
          </Html>
        ) : (
          <Suspense fallback={<Loader />}>
            <OrbitControls />
            <Model item={item} />
          </Suspense>
        )}
      </Canvas>
    </div>
  );
}
