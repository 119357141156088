import { useState, useEffect } from "react";
import useStore from "../zustand";
import produce from "immer";
import useScrollTo from "react-spring-scroll-to-hook";
import useMeasure from "react-use-measure";

export default function Tags({ length, width, open }) {
  const { scrollTo } = useScrollTo();
  const projecttags = useStore((s) => s.projecttags);
  const currentprojecttag = useStore((s) => s.selectedtags);
  const [openC, setOpenC] = useState(false);
  const [ref, { height }] = useMeasure();
  var toys = projecttags.slice();
  var arr = toys.sort((a, b) =>
    a.localeCompare(b, undefined, { sensitivity: "base" })
  );
  useEffect(() => {
    if (width < 700) {
      setOpenC(false);
    }
  }, [open]);
  return (
    <>
      <div
        style={{
          textAlign: "center",
          padding: 2,
          backgroundColor: openC ? "rgb(59, 130, 246)" : "#475569",
          color: "white",
          cursor: "pointer",
          lineHeight: "18px",
          height: 24,
        }}
        onClick={() => {
          setOpenC(!openC);
        }}
        className="buttonwhite"
      >
        Tags {length > 0 && <>({length})</>}
      </div>
      <div
        style={{
          lineHeight: "11px",
          fontSize: 12,
          overflow: openC ? "visible" : "hidden",
          margin: openC ? 8 : 0,
          height: openC ? height : 0,
        }}
      >
        <div ref={ref}>
          {arr.map((tag, i) => (
            <div
              key={i}
              style={{
                display: "inline-block",
                margin: width > 700 ? "0px 2px" : "2px 6px",
                padding: "0px 0px",
                color: currentprojecttag.includes(tag) ? "#3b82f6" : "#64748B",
                borderRadius: 12,
                cursor: "pointer",
                fontVariationSettings: '"phon" 0, "phtw" 0, "phth" 0',
              }}
              onClick={() => {
                const index = currentprojecttag.findIndex(
                  (prod) => prod === tag
                );
                useStore.setState(
                  produce((state) => {
                    if (state.selectedtags.includes(tag)) {
                      state.selectedtags.splice(index, 1);
                    } else {
                      state.selectedtags.push(tag);
                    }
                  })
                );
                scrollTo();
              }}
              className="smalltext"
            >
              #{tag}
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
