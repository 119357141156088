import {
  useState,
  useEffect,
  useRef,
  useMemo,
  useCallback,
  Suspense,
  lazy,
} from "react";
import useStore from "../zustand";
import produce from "immer";
import useScrollTo from "react-spring-scroll-to-hook";
import useMeasure from "react-use-measure";

export default function Projects({ length, width, api, open }) {
  const { scrollTo } = useScrollTo();
  const titletags = useStore((s) => s.titletags);
  const currenttitletag = useStore((s) => s.selectedtitles);

  const [openP, setOpenP] = useState(false);
  const [ref, { height }] = useMeasure();

  var toys = titletags.slice();
  var arr = toys.sort((a, b) =>
    a.localeCompare(b, undefined, { sensitivity: "base" })
  );
  useEffect(() => {
    if (width < 700) {
      setOpenP(false);
    }
  }, [open]);
  return (
    <>
      <div
        style={{
          textAlign: "center",
          padding: 2,
          backgroundColor: openP ? "rgb(59, 130, 246)" : "#475569",
          cursor: "pointer",
          lineHeight: "18px",
          height: 24,
        }}
        onClick={() => {
          setOpenP(!openP);
        }}
        className="buttonwhite"
      >
        Countries {length > 0 && <>({length})</>}
      </div>
      <div
        style={{
          margin: openP ? 8 : 0,
          lineHeight: "11px",
          fontSize: 12,
          overflow: openP ? "visible" : "hidden",
          height: openP ? height : 0,
          fontVariationSettings: '"phon" 0, "phtw" 0, "phth" 0',
        }}
      >
        <div ref={ref} className="buttonlist">
          {arr.map((tag, i) => (
            <>
              <div
                key={i}
                style={{
                  display: "inline-block",
                  margin: width > 700 ? "0px 2px" : "2px 6px",
                  color: currenttitletag.includes(tag) ? "#3b82f6" : "#64748B",
                  cursor: "pointer",
                }}
                onClick={() => {
                  const index = currenttitletag.findIndex(
                    (prod) => prod === tag
                  );
                  useStore.setState(
                    produce((state) => {
                      if (state.selectedtitles.includes(tag)) {
                        state.selectedtitles.splice(index, 1);
                      } else {
                        state.selectedtitles.push(tag);
                      }
                    })
                  );
                  scrollTo();
                  api.start({
                    y: 16,
                    x: 16,
                  });
                }}
                className="smalltext"
              >
                {tag}
              </div>
              {i !== arr.length - 1 && (
                <span style={{ color: "rgb(192, 132, 252)" }}>|</span>
              )}
            </>
          ))}
        </div>
      </div>
    </>
  );
}
