import { useState, Suspense, useEffect, useRef } from "react";

import Masonry from "./Masonry/Masonry";
import Content from "./Content/Content";

import { suspend } from "suspend-react";
import useStore from "./zustand";
import produce from "immer";
import Button from "./Button/Button";
import Intro from "./Intro";
import Contact from "./Contact";

function getUnique(arr, index) {
  const unique = arr
    .map((e) => e[index])
    // store the keys of the unique objects
    .map((e, i, final) => final.indexOf(e) === i && i)
    // eliminate the dead keys & store unique objects
    .filter((e) => arr[e])
    .map((e) => arr[e]);
  return unique;
}
function Shuffle({ images }) {
  const [shuffled, setShuffled] = useState();
  const [ready, setReady] = useState([]);

  useEffect(() => setShuffled([...images]), [images]);
  useEffect(() => {
    if (shuffled) {
      setReady(getUnique(shuffled, "id"));
    }
  }, [shuffled]);
  return shuffled ? <Masonry data={ready} /> : <></>;
}
function Filter({ data }) {
  const selectedtitles = useStore((s) => s.selectedtitles);
  const selectedtags = useStore((s) => s.selectedtags);
  const selectedarchitects = useStore((s) => s.selectedarchitects);
  const selectedtypology = useStore((s) => s.selectedtypology);
  const selectedcity = useStore((s) => s.selectedcity);

  // const [selectedtitles, setSelectedtitles] = useState([]);
  // const [selectedtags, setSelectedtags] = useState([]);
  // const [selectedarchitects, setSelectedarchitects] = useState([]);
  // const [selectedtypology, setselectedtypology] = useState([]);
  // const [selectedcity, setselectedcity] = useState([]);

  const [images, setImages] = useState([]);
  function resetTags() {
    setImages([]);
  }
  useEffect(() => {
    if (data) {
      data.map((item) => {
        // project title as tag
        if (item.title !== undefined) {
          useStore.setState(
            produce((state) => {
              if (
                !state.titletags.includes(item.title) &&
                item.title !== "NOT A PROJECT"
              )
                state.titletags.push(item.title);
            })
          );
        }

        // push tags to zustand
        if (item.tags) {
          item.tags.split(", ").map((tag) => {
            // useStore.setState((state) => state.projecttags.push(tag))
            useStore.setState(
              produce((state) => {
                if (!state.projecttags.includes(tag))
                  state.projecttags.push(tag);
              })
            );
          });
        }
        if (item.architect) {
          item.architect.split(", ").map((architect) => {
            // useStore.setState((state) => state.projecttags.push(tag))
            useStore.setState(
              produce((state) => {
                if (!state.architecttags.includes(architect))
                  state.architecttags.push(architect);
              })
            );
          });
        }
        if (item.city) {
          item.city.split(", ").map((city) => {
            // useStore.setState((state) => state.projecttags.push(tag))
            useStore.setState(
              produce((state) => {
                if (!state.citytags.includes(city)) state.citytags.push(city);
              })
            );
          });
        }
        if (item.typology) {
          item.typology.split(", ").map((typology) => {
            // useStore.setState((state) => state.projecttags.push(tag))
            useStore.setState(
              produce((state) => {
                if (!state.typologytags.includes(typology))
                  state.typologytags.push(typology);
              })
            );
          });
        }
        if (item.contact) {
          // useStore.setState((state) => (state.contact = item.contact));
          useStore.setState(
            produce((state) => {
              state.contact = item.contact;
            })
          );
        }
        if (item.contacttwn) {
          // useStore.setState((state) => (state.contact = item.contact));
          useStore.setState(
            produce((state) => {
              state.contacttwn = item.contacttwn;
            })
          );
        }
      });
    }
  }, [data]);

  useEffect(() => {
    // reset stores
    resetTags();

    if (data) {
      data.map((item) => {
        // push images
        if (item.images) {
          // loop all images
          item.images.map((image) => {
            // if city and typology
            if (
              selectedtitles?.length === 0 &&
              selectedtags?.length === 0 &&
              selectedarchitects?.length === 0 &&
              selectedtypology.some((i) =>
                image.typology.split(", ").includes(i)
              ) &&
              selectedcity.some((i) => image.city.split(", ").includes(i))
            ) {
              // console.log("city and typology");
              setImages((images) => [
                ...images,
                {
                  url: image.url,
                  small: image.small,
                  big: image.big,
                  width: image.width,
                  height: image.height,
                  architect: image.architect,
                  typology: image.typology,
                  city: image.city,
                  tags: image.tags,
                  id: image.id,
                  title: image.title,
                  type: image.type,

                  project: item.title,
                },
              ]);
            }
            if (
              selectedtitles?.length === 0 &&
              selectedtags?.length === 0 &&
              selectedtypology.some((i) =>
                image.typology.split(", ").includes(i)
              ) &&
              selectedcity.some((i) => image.city.split(", ").includes(i)) &&
              selectedarchitects.some((i) =>
                image.architect.split(", ").includes(i)
              )
            ) {
              // console.log("city and architect and typology");
              setImages((images) => [
                ...images,
                {
                  url: image.url,
                  small: image.small,
                  big: image.big,
                  width: image.width,
                  height: image.height,
                  architect: image.architect,
                  typology: image.typology,
                  city: image.city,
                  tags: image.tags,
                  id: image.id,
                  title: image.title,
                  type: image.type,

                  project: item.title,
                },
              ]);
            }
            if (
              selectedtitles?.length === 0 &&
              selectedtags?.length === 0 &&
              selectedtypology.some((i) =>
                image.typology.split(", ").includes(i)
              ) &&
              selectedcity?.length === 0 &&
              selectedarchitects.some((i) =>
                image.architect.split(", ").includes(i)
              )
            ) {
              // console.log("architect and typology");
              setImages((images) => [
                ...images,
                {
                  url: image.url,
                  small: image.small,
                  big: image.big,
                  width: image.width,
                  height: image.height,
                  architect: image.architect,
                  typology: image.typology,
                  city: image.city,
                  tags: image.tags,
                  id: image.id,
                  title: image.title,
                  type: image.type,

                  project: item.title,
                },
              ]);
            }
            if (
              selectedtitles?.length === 0 &&
              selectedtags?.every((i) => image.tags.split(", ").includes(i)) &&
              selectedtypology.length === 0 &&
              selectedcity.some((i) => image.city.split(", ").includes(i)) &&
              selectedarchitects.some((i) =>
                image.architect.split(", ").includes(i)
              )
            ) {
              // console.log("city and architect and tags");
              setImages((images) => [
                ...images,
                {
                  url: image.url,
                  small: image.small,
                  big: image.big,
                  width: image.width,
                  height: image.height,
                  architect: image.architect,
                  typology: image.typology,
                  city: image.city,
                  tags: image.tags,
                  id: image.id,
                  title: image.title,
                  type: image.type,

                  project: item.title,
                },
              ]);
            }

            // no title and no architect and no city
            if (
              selectedtitles?.length === 0 &&
              selectedarchitects?.length === 0 &&
              selectedcity?.length === 0
            ) {
              if (selectedtags?.length !== 0 || selectedtypology.length !== 0) {
                // map all selected tags and typology
                if (
                  selectedtags.every((i) =>
                    image.tags.split(", ").includes(i)
                  ) &&
                  selectedtypology.length === 0
                ) {
                  // console.log("only tags");
                  setImages((images) => [
                    ...images,
                    {
                      url: image.url,
                      small: image.small,
                      big: image.big,
                      width: image.width,
                      height: image.height,
                      architect: image.architect,
                      typology: image.typology,
                      city: image.city,
                      tags: image.tags,
                      id: image.id,
                      title: image.title,
                      type: image.type,

                      project: item.title,
                    },
                  ]);
                }
                if (
                  selectedtypology.some((i) =>
                    image.typology.split(", ").includes(i)
                  ) &&
                  selectedtags.length === 0
                ) {
                  // console.log("only typology");
                  setImages((images) => [
                    ...images,
                    {
                      url: image.url,
                      small: image.small,
                      big: image.big,
                      width: image.width,
                      height: image.height,
                      architect: image.architect,
                      typology: image.typology,
                      city: image.city,
                      tags: image.tags,
                      id: image.id,
                      title: image.title,
                      type: image.type,

                      project: item.title,
                    },
                  ]);
                }
                if (
                  selectedtypology.some((i) =>
                    image.typology.split(", ").includes(i)
                  ) &&
                  selectedtags.every((i) => image.tags.split(", ").includes(i))
                ) {
                  // console.log("tags and typology");
                  setImages((images) => [
                    ...images,
                    {
                      url: image.url,
                      small: image.small,
                      big: image.big,
                      width: image.width,
                      height: image.height,
                      architect: image.architect,
                      typology: image.typology,
                      city: image.city,
                      tags: image.tags,
                      id: image.id,
                      title: image.title,
                      type: image.type,

                      project: item.title,
                    },
                  ]);
                }
              }
              // nothing selected
              if (selectedtags?.length === 0 && selectedtypology.length === 0) {
                // console.log("no tag and no title is selcted");
                setImages((images) => [
                  ...images,
                  {
                    url: image.url,
                    small: image.small,
                    big: image.big,
                    width: image.width,
                    height: image.height,
                    architect: image.architect,
                    typology: image.typology,
                    city: image.city,
                    tags: image.tags,
                    id: image.id,
                    title: image.title,
                    type: image.type,

                    project: item.title,
                  },
                ]);
              }
            } else {
              selectedtitles.map((projecttitle) => {
                if (
                  (selectedtags?.length !== 0 ||
                    selectedtypology.length !== 0) &&
                  selectedarchitects.length === 0 &&
                  selectedcity.length === 0
                ) {
                  if (item.title === projecttitle) {
                    if (
                      selectedtags.some((i) =>
                        image.tags.split(", ").includes(i)
                      ) ||
                      selectedtypology.some((i) =>
                        image.typology.split(", ").includes(i)
                      )
                    ) {
                      // console.log("title and tags");
                      setImages((images) => [
                        ...images,
                        {
                          url: image.url,
                          small: image.small,
                          big: image.big,
                          width: image.width,
                          height: image.height,
                          architect: image.architect,
                          typology: image.typology,
                          city: image.city,
                          tags: image.tags,
                          id: image.id,
                          title: image.title,
                          type: image.type,

                          project: item.title,
                        },
                      ]);
                    }
                  }
                } else {
                  if (item.title === projecttitle) {
                    if (
                      selectedtags.every((i) =>
                        image.tags.split(", ").includes(i)
                      ) &&
                      selectedcity.some((i) =>
                        image.city.split(", ").includes(i)
                      )
                    ) {
                      setImages((images) => [
                        ...images,
                        {
                          url: image.url,
                          small: image.small,
                          big: image.big,
                          width: image.width,
                          height: image.height,
                          architect: image.architect,
                          typology: image.typology,
                          city: image.city,
                          tags: image.tags,
                          id: image.id,
                          title: image.title,
                          type: image.type,

                          project: item.title,
                        },
                      ]);
                    }
                    if (
                      selectedarchitects.some((i) =>
                        image.architect.split(", ").includes(i)
                      ) &&
                      selectedcity.length === 0
                    ) {
                      if (
                        selectedtags.every((i) =>
                          image.tags.split(", ").includes(i)
                        )
                      ) {
                        // console.log("architect and title and tag");
                        setImages((images) => [
                          ...images,
                          {
                            url: image.url,
                            small: image.small,
                            big: image.big,
                            width: image.width,
                            height: image.height,
                            architect: image.architect,
                            typology: image.typology,
                            city: image.city,
                            tags: image.tags,
                            id: image.id,
                            title: image.title,
                            type: image.type,

                            project: item.title,
                          },
                        ]);
                      }
                      if (
                        selectedtypology.some((i) =>
                          image.typology.split(", ").includes(i)
                        )
                      ) {
                        // console.log("architect and title and typology");
                        setImages((images) => [
                          ...images,
                          {
                            url: image.url,
                            small: image.small,
                            big: image.big,
                            width: image.width,
                            height: image.height,
                            architect: image.architect,
                            typology: image.typology,
                            city: image.city,
                            tags: image.tags,
                            id: image.id,
                            title: image.title,
                            type: image.type,

                            project: item.title,
                          },
                        ]);
                      }
                      if (
                        selectedtypology.some((i) =>
                          image.typology.split(", ").includes(i)
                        ) &&
                        selectedtags.some((i) =>
                          image.tags.split(", ").includes(i)
                        )
                      ) {
                        // console.log(
                        //   "architect and title and tags and typology"
                        // );
                        setImages((images) => [
                          ...images,
                          {
                            url: image.url,
                            small: image.small,
                            big: image.big,
                            width: image.width,
                            height: image.height,
                            architect: image.architect,
                            typology: image.typology,
                            city: image.city,
                            tags: image.tags,
                            id: image.id,
                            title: image.title,
                            type: image.type,

                            project: item.title,
                          },
                        ]);
                      }
                      if (
                        selectedtypology.length === 0 &&
                        selectedtags.length === 0
                      ) {
                        setImages((images) => [
                          ...images,
                          {
                            url: image.url,
                            small: image.small,
                            big: image.big,
                            width: image.width,
                            height: image.height,
                            architect: image.architect,
                            typology: image.typology,
                            city: image.city,
                            tags: image.tags,
                            id: image.id,
                            title: image.title,
                            type: image.type,

                            project: item.title,
                          },
                        ]);
                      }
                    }
                    if (
                      selectedcity.some((i) =>
                        image.city.split(", ").includes(i)
                      ) &&
                      selectedarchitects.length === 0 &&
                      selectedtypology.length === 0 &&
                      selectedtags.length === 0
                    ) {
                      // console.log("city and title");
                      setImages((images) => [
                        ...images,
                        {
                          url: image.url,
                          small: image.small,
                          big: image.big,
                          width: image.width,
                          height: image.height,
                          architect: image.architect,
                          typology: image.typology,
                          city: image.city,
                          tags: image.tags,
                          id: image.id,
                          title: image.title,
                          type: image.type,

                          project: item.title,
                        },
                      ]);
                    }
                    if (
                      selectedarchitects.length !== 0 &&
                      selectedcity.length !== 0
                    ) {
                      if (
                        selectedcity.some((i) =>
                          image.city.split(", ").includes(i)
                        ) &&
                        selectedarchitects.some((i) =>
                          image.architect.split(", ").includes(i)
                        )
                      ) {
                        // console.log("architect,city and tags?");
                        setImages((images) => [
                          ...images,
                          {
                            url: image.url,
                            small: image.small,
                            big: image.big,
                            width: image.width,
                            height: image.height,
                            architect: image.architect,
                            typology: image.typology,
                            city: image.city,
                            tags: image.tags,
                            id: image.id,
                            title: image.title,
                            type: image.type,

                            project: item.title,
                          },
                        ]);
                      }
                    }
                    if (
                      selectedarchitects.length === 0 &&
                      selectedcity.length === 0
                    ) {
                      // console.log("no architect and city");
                      setImages((images) => [
                        ...images,
                        {
                          url: image.url,
                          small: image.small,
                          big: image.big,
                          width: image.width,
                          height: image.height,
                          architect: image.architect,
                          typology: image.typology,
                          city: image.city,
                          tags: image.tags,
                          id: image.id,
                          title: image.title,
                          type: image.type,

                          project: item.title,
                        },
                      ]);
                    }
                  }
                }
              });
              if (selectedtitles.length === 0) {
                if (
                  selectedcity.some((i) =>
                    image.city.split(", ").includes(i)
                  ) &&
                  selectedarchitects.length === 0 &&
                  selectedtags.length === 0 &&
                  selectedtypology.length === 0
                ) {
                  // console.log("only city");
                  setImages((images) => [
                    ...images,
                    {
                      url: image.url,
                      small: image.small,
                      big: image.big,
                      width: image.width,
                      height: image.height,
                      architect: image.architect,
                      typology: image.typology,
                      city: image.city,
                      tags: image.tags,
                      id: image.id,
                      title: image.title,
                      type: image.type,

                      project: item.title,
                    },
                  ]);
                }
                if (
                  selectedarchitects.some((i) =>
                    image.architect.split(", ").includes(i)
                  ) &&
                  selectedcity.length === 0 &&
                  selectedtags.length === 0 &&
                  selectedtypology.length === 0
                ) {
                  // console.log("only architect");
                  setImages((images) => [
                    ...images,
                    {
                      url: image.url,
                      small: image.small,
                      big: image.big,
                      width: image.width,
                      height: image.height,
                      architect: image.architect,
                      typology: image.typology,
                      city: image.city,
                      tags: image.tags,
                      id: image.id,
                      title: image.title,
                      type: image.type,

                      project: item.title,
                    },
                  ]);
                }
                if (
                  selectedarchitects.some((i) =>
                    image.architect.split(", ").includes(i)
                  ) &&
                  selectedcity.some((i) =>
                    image.city.split(", ").includes(i)
                  ) &&
                  selectedtags.length === 0 &&
                  selectedtypology.length === 0
                ) {
                  // console.log("architect and city");
                  setImages((images) => [
                    ...images,
                    {
                      url: image.url,
                      small: image.small,
                      big: image.big,
                      width: image.width,
                      height: image.height,
                      architect: image.architect,
                      typology: image.typology,
                      city: image.city,
                      tags: image.tags,
                      id: image.id,
                      title: image.title,
                      type: image.type,

                      project: item.title,
                    },
                  ]);
                }
                if (
                  selectedcity.some((i) =>
                    image.city.split(", ").includes(i)
                  ) &&
                  selectedarchitects.length === 0 &&
                  selectedtags.every((i) =>
                    image.tags.split(", ").includes(i)
                  ) &&
                  selectedtypology.length === 0
                ) {
                  // console.log("city and tags");
                  setImages((images) => [
                    ...images,
                    {
                      url: image.url,
                      small: image.small,
                      big: image.big,
                      width: image.width,
                      height: image.height,
                      architect: image.architect,
                      typology: image.typology,
                      city: image.city,
                      tags: image.tags,
                      id: image.id,
                      title: image.title,
                      type: image.type,

                      project: item.title,
                    },
                  ]);
                }
                if (
                  selectedarchitects.some((i) =>
                    image.architect.split(", ").includes(i)
                  ) &&
                  selectedcity.length === 0 &&
                  selectedtags.every((i) =>
                    image.tags.split(", ").includes(i)
                  ) &&
                  selectedtypology.length === 0
                ) {
                  // console.log("architect and tags");
                  setImages((images) => [
                    ...images,
                    {
                      url: image.url,
                      small: image.small,
                      big: image.big,
                      width: image.width,
                      height: image.height,
                      architect: image.architect,
                      typology: image.typology,
                      city: image.city,
                      tags: image.tags,
                      id: image.id,
                      title: image.title,
                      type: image.type,

                      project: item.title,
                    },
                  ]);
                }

                //
                if (
                  selectedtags.length !== 0 &&
                  selectedtypology.length !== 0 &&
                  (selectedarchitects.some((i) =>
                    image.architect.split(", ").includes(i)
                  ) ||
                    selectedcity.some((i) =>
                      image.city.split(", ").includes(i)
                    )) &&
                  (selectedtags.every((i) =>
                    image.tags.split(", ").includes(i)
                  ) ||
                    selectedtypology.some((i) =>
                      image.typology.split(", ").includes(i)
                    ))
                ) {
                  // console.log("architect and city and tags");
                  setImages((images) => [
                    ...images,
                    {
                      url: image.url,
                      small: image.small,
                      big: image.big,
                      width: image.width,
                      height: image.height,
                      architect: image.architect,
                      typology: image.typology,
                      city: image.city,
                      tags: image.tags,
                      id: image.id,
                      title: image.title,
                      type: image.type,

                      project: item.title,
                    },
                  ]);
                }
              }
            }
          });
        }
      });
    }
  }, [
    data,
    selectedtags,
    selectedtitles,
    selectedcity,
    selectedtypology,
    selectedarchitects,
  ]);
  useEffect(() => {
    let arr = [];
    let finarr = [];
    arr.push(images);
    arr.map((items) => {
      items.map((item) => {
        finarr.push(item);
      });
    });
    useStore.setState(
      produce((state) => {
        state.data = finarr.sort((a, b) => a.project.localeCompare(b.project));
      })
    );
  }, [images]);

  return <>{images && <Shuffle images={images} />}</>;
}

function Post() {
  const data = suspend(async () => {
    const res = await fetch(
      `https://unprofessional.studio/backend/datatravel.json`
    );
    return await res.json();
  }, []);
  return <Filter data={data} />;
}

function App() {
  return (
    <>
      <Suspense fallback={<Intro />}>
        <Post />
        <Button />
        <Content />
        <Contact />
      </Suspense>
    </>
  );
}

export default App;
